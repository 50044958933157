import './App.css';

import React from 'react';

import logo from './logo.svg';
import { Plays } from './Plays';

function App() {
  return (
    <div className="App">
      <Plays />
    </div>
  );
}

export default App;
