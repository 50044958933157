import { Avatar, Button, List, Skeleton } from 'antd';
import axios from 'axios';
import React from 'react';

//import reqwest from 'reqwest';

const count = 3;
const fakeDataUrl = `https://api.spotify.joriscoenen.nl/plays/3fm/`;

interface PlaysState {
  initLoading: boolean;
  loading: boolean;
  data: {
    loading: boolean;
    email?: string;
  }[];
}

export class Plays extends React.Component {
  state: PlaysState = {
    initLoading: true,
    loading: false,
    data: [],
  };

  async componentDidMount() {
    const res = await this.getData();
    console.log(res);
    this.setState({
      initLoading: false,
      data: res.results,
    });
  }

  getData = async (): Promise<any> => {
    const res = await axios.get(fakeDataUrl, { responseType: "json" });
    console.log(res.data);
    return res.data;
    //callback(res.data);
  };

  onLoadMore = async () => {
    this.setState((prevState: PlaysState) => {
      return {
        loading: true,
        data: prevState.data.concat(
          [...new Array(count)].map(() => ({ loading: true }))
        ),
      };
    });
    const res = await this.getData();
    this.setState(
      (prevState: PlaysState) => {
        return {
          data: prevState.data
            .filter((entry) => !entry.loading)
            .concat(res.results),
          loading: false,
        };
      },
      () => {
        // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
        // In real scene, you can using public method of react-virtualized:
        // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
        window.dispatchEvent(new Event("resize"));
      }
    );
  };

  render() {
    const { initLoading, loading, data } = this.state;
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>loading more</Button>
        </div>
      ) : null;

    return (
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a key="list-loadmore-edit">edit</a>,
              <a key="list-loadmore-more">more</a>,
            ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={<a href="https://ant.design">{item.email}</a>}
                description="Ant Design, a design language for background applications, is refined by Ant UED Team"
              />
              <div>content</div>
            </Skeleton>
          </List.Item>
        )}
      />
    );
  }
}
